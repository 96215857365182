import React from "react"
import { graphql } from "gatsby"
import Page from "./Page"
import Section from "../components/UI/Section"
import Container from "../components/UI/Container"
import Content, { HTMLContent } from "../components/Content"
import PropTypes from "prop-types"
import { Box, Flex, Heading, Text } from "rebass/styled-components"
import CustomImage from "../components/UI/CustomImage"
import Header from "../components/Blocks/Header"

export const BlogRawTemplate = ({ content, contentComponent, img, title, date }) => {
  const PostContent = contentComponent || Content
  return (
    <>
      <Header data={{ bigTitle: title, blockSettings: { bgImg: img, bg: "#0d002e" } }} />
      <Section>
        <Container>
          <Flex alignItems="stretch" mx={[0, -2]}>
            <Box px={[0, 2]} width={[1, 2 / 3]}>
              <Heading>{title}</Heading>
              <Text>{date}</Text>
              <PostContent content={content} />
            </Box>
            <CustomImage img={img} width={[1, 1 / 3]} px={[0, 2]} />
          </Flex>
        </Container>
      </Section>
    </>
  )
}

const BlogTemplate = ({ data }) => {
  const infos = {
    title: data.markdownRemark.frontmatter.title,
    description: data.markdownRemark.frontmatter.description,
    img: data.markdownRemark.frontmatter.img,
    date: data.markdownRemark.frontmatter.date,
  }
  return (
    <Page title={infos.title} description={infos.description}>
      <BlogRawTemplate
        title={infos.title}
        content={data.markdownRemark.html}
        img={infos.img}
        date={infos.date}
        contentComponent={HTMLContent}
      />
    </Page>
  )
}

BlogTemplate.propTypes = {
  data: PropTypes.object,
}
BlogRawTemplate.propTypes = {
  content: PropTypes.any,
  contentComponent: PropTypes.any,
  img: PropTypes.any,
  title: PropTypes.string,
  date: PropTypes.string,
}
export default BlogTemplate

export const pageQuery = graphql`
  query BlogByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "DD/MM/YYYY")
        img {
          childImageSharp {
            fluid(maxWidth: 200, quality: 64) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description
        tags
      }
      html
    }
  }
`
